.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, #CEDFFF 20%, #FFFFFF 65%);
    height: 100vh;
    width: 100vw;
  }

  .vector {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    margin-top: 10%;
    width: 180px;
    z-index: 1;
  }

  .app-links {

    .store-app {
      height: 100px;
      width: 200px;
      object-fit: cover;
      margin: 0px 20px;
    }
}
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* Media query pour les tablettes */
  @media screen and (max-width: 768px) {
    .footer-links {
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 50px;
    }
  }

  .footer-links a {
    color: #6600B7;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }